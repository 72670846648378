<template>
  <div id="portofolio">
    <div class="pt-[50px] px-10 lg:pt-[150px] 2xl:px-60 flex-container">
      <div data-aos="fade-up" data-aos-offset="300" data-aos-duration="500" class="text-center">
        <h1 class="text-lg font-bold text-hideung pb-2">
          Our
          <span class="text-primary">Portofolio</span>
        </h1>
        <h2 class="text-md text-abhu font-normal pb-10">The following are some of the projects we have completed, where
          the projects are in the form of websites and mobile applications</h2>
      </div>
      <div data-aos="fade-down" data-aos-offset="500" data-aos-duration="500" class="flex flex-wrap">
        <div class="w-full">
          <div class="justify-items-center grid mx-8 lg:grid-cols-3">
            <div class="block max-w-[350px] rounded-[20px] shadow-lg bg-light mb-10 lg:mb-20 xl:w-[317px] p-5">

              <img class="mx-auto h-[100px] lg:h-[150px]" src="../assets/porto1.png" />
              <h1 class="text-center pt-5">PT.Batara Swastika Harati</h1>
              <div class="pt-10 text-center">
                <a target="_blank" href="http://haraticonsulting.co.id/"
                  class=" transition duration-300 ease-in-out hover:bg-primary hover:text-bodas text-primary font-bold bg-light py-3 px-10 border-2 border-primary rounded-full">Go
                  To Project</a>
              </div>

            </div>
            <div class="block max-w-[350px] rounded-[20px] shadow-lg bg-light mb-10 lg:mb-20 xl:w-[317px] p-5">

              <img class="mx-auto h-[100px] lg:h-[150px]" src="../assets/porto2.png" />
              <h1 class="text-center pt-5">Swastika Journal</h1>
              <div class="pt-10 text-center">
                <a target="_blank" href="https://swastikajournal.com/" 
                  class=" transition duration-300 ease-in-out hover:bg-primary hover:text-bodas text-primary font-bold bg-light py-3 px-10 border-2 border-primary rounded-full">Go
                  To Project</a>
              </div>

            </div>
            <div class="block max-w-[350px] rounded-[20px] shadow-lg bg-light mb-10 lg:mb-20 xl:w-[317px] p-5">

              <img class="mx-auto h-[100px] lg:h-[150px]" src="../assets/porto3.png" />
              <h1 class="text-center pt-5">Maharati Research Institute</h1>
              <div class="pt-10 text-center">
                <a target="_blank" href="https://maharatiinstitute.com/"
                  class=" transition duration-300 ease-in-out hover:bg-primary hover:text-bodas text-primary font-bold bg-light py-3 px-10 border-2 border-primary rounded-full">Go
                  To Project</a>
              </div>

            </div>
          </div>
          <!-- <a class="text-abhu font-bold bg-primary py-5 pr-3 rounded-full">
              <span class="font-bold bg-second text-light py-5 px-6 rounded-full">1</span> DOAJ Indexing Assistance
          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import "@/assets/tailwind.css";

export default {};
</script>
    
<style></style>