<template>
  <div id="hero">
    <div class="pt-[100px] px-10 lg:pt-atas 2xl:px-40 flex-container bg-cats bg-no-repeat 2xl:bg-[length:1920px_650px]">
      <div class="flex flex-wrap">
        <div data-aos="fade-down" data-aos-duration="1500" class="w-full self-center pl-0 lg:w-1/2">
          <!-- <a class="text-abhu font-bold bg-lemon py-3 px-5 rounded-lg"
            >Catra Research Institute</a
          > -->
          <h1 class="text-lg font-bold mt-0">
              CREATING <span class="text-light">VALUE,</span> 
            <span class="block">DELIVERING <span class="text-light">EXCELLENCE</span></span>
          </h1>
          <h2 class="text-base font-semibold mb-12">We help our clients identify their issues, develope solutions and take action</h2>
          <a
            href="https://wa.me/6281120023046"
            class="transition duration-300 ease-in-out hover:bg-second bg-primary text-bodas font-bold border-2 border-second py-4 px-5 rounded-full"
            >Conctact Us !</a
          >
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="w-full self-end px-4 lg:w-1/2">
          <div class="mt-10 pb-10">
            <img
              src="../assets/home1.png"
              alt="catra"
              class="max-w-full mx-auto pb-10 pt-16"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-cover bg-center bg-no-repeat h-[700px] bg-cats pt-0">
      <div class="grid grid-cols-2 gap-10 pt-15 h-[700px]">
        <div class="text-left p-60">
          <p class="text-4xl font-extrabold">
            SCIENTIFICS
            <a class="text-primary">RESEARCH</a>
          </p>
          <p class="text-4xl font-extrabold">
            JOURNAL
          </p>
          <a href="https://api.whatsapp.com/send/?phone=6281120011644&text&type=phone_number&app_absent=0">
          <button
            type="button"
            class="text-bodas bg-primary hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-primary dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >Consultation NOW !</button>
        </a>
        </div>
        <div class="place-content-center">
          <div class>
            <img class="h-[629px] w-[629px]" src="../assets/home1.png" alt="homie" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style>
</style>