<template>
  <div class="home">
    <div>
      <HomeCatra/>
      <DivisionBusiness/>
      <OurService/>
      <AboutUs/>
      <OurSubsidiary/>
      <OurPortofolio/>
      <FooterCatra/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/tailwind.css';
import HomeCatra from '@/components/HomeCatra.vue';
import DivisionBusiness from '@/components/DivisionBusiness.vue';
import OurService from '@/components/OurService.vue';
import AboutUs from '@/components/AboutUs.vue';
import OurSubsidiary from '@/components/OurSubsidiary.vue';
import OurPortofolio from '@/components/OurPortofolio.vue';
import FooterCatra from '@/components/FooterCatra.vue';

export default {
  name: 'HomeView',
  components: {
    HomeCatra,
    DivisionBusiness,
    OurService,
    AboutUs,
    OurSubsidiary,
    OurPortofolio,
    FooterCatra
}
}
</script>
