<template>
  <div id="subsidiary">
    <div class="bg-no-repeat bg-cover 2xl:px-60 lg:pb-20">
      <div class="grid justify-items-center pt-20">
        <div data-aos="fade-down" data-aos-offset="500" data-aos-duration="500" class="transition max-w-[1150px]  rounded-[20px] shadow-2xl bg-light xl:w-[1150px]">
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-4 p-7">
            <div class="self-center rounded-lg transition">
              <h1 class="text-hideung text-lg font-bold text-left">
                Our Subsidiary
              </h1>
            </div>
            <div class="rounded-lg transform hover:scale-105 duration-300">
                <img class="mx-auto h-[100px] lg:h-[100px]" src="../assets/adpsi.png" />
            </div>
            <div class="rounded-lg transform hover:scale-105 duration-300">
                <img class="mx-auto h-[100px] lg:h-[100px]" src="../assets/jari.png" />
            </div>
            <div class="rounded-lg transform hover:scale-105 duration-300">
                <img class="mx-auto h-[100px] lg:h-[100px]" src="../assets/lsp.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import "@/assets/tailwind.css";

export default {};
</script>
    
<style></style>