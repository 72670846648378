<template>
  <div id="business">
    <div class="bg-no-repeat bg-cover px-10 2xl:px-60 lg:pb-20">
      <div class="grid justify-items-center pt-20">
        <div data-aos="fade-up" data-aos-duration="1500"
          class="transition max-w rounded-[20px] shadow-2xl bg-light mb-10 lg:mb-20 xl:w-[1150px]">
          <div class="text-center grid grid-cols-1 gap-4 lg:grid-cols-3 p-7">
            <div class=" rounded-lg transition">
              <h1 class="text-hideung text-lg font-bold text-left">
                Our Division Business
              </h1>
            </div>
            <div class="rounded-lg transform hover:scale-105 duration-300">
              <a href="https://maharatiinstitute.com/" target="_blank">
                <img class="mt-5 mx-auto h-[100px] lg:h-[110px]" src="../assets/maharati1.png" />
              </a>
            </div>
            <div class="rounded-lg transform hover:scale-105 duration-300">
              <a href="https://swastikajournal.com/" target="_blank">
                <img class="mt-5 mx-auto h-[100px] lg:h-[110px]" src="../assets/swastika1.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="text-center grid grid-cols-2 gap-4 lg:grid-cols-4 pb-5">
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/ijesss" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/1ijesss.png" />
            <h1 class="text-abhu lg:px-10">
              International Journal of Environmental Sustainability and Social
              Science
            </h1>
          </a>
        </div>
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/ahjpm" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/2ahjpm.png" />
            <h1 class="text-abhu lg:px-20">
              Akutansi dan Humaniora Jurnal Pengabdian Masyarakat
            </h1>
          </a>
        </div>
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/jtep" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/3jtep.png" />
            <h1 class="text-abhu lg:px-20">
              Journal of Tourism and Economics Policy
            </h1>
          </a>
        </div>
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/pjlel" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/4pjlel.png" />

            <h1 class="text-abhu lg:px-20">
              Protection: Journal of Land And Environmental Law
            </h1>
          </a>
        </div>
      </div>
      <div class="text-center grid grid-cols-2 gap-4 lg:grid-cols-3 lg:px-44">
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/JoGTA" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/5jogta.png" />
          </a>
          <h1 class="text-abhu lg:px-16">
            Journal of Governance, Taxation and Auditing
          </h1>
        </div>
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/jibb" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/JIBB.png" />
            <h1 class="text-abhu lg:px-16">
              Journal of Integrated Business and Banking
            </h1>
          </a>
        </div>
        <div class="rounded-lg transition duration-300 ease-in-out hover:bg-primary">
          <a href="https://journalkeberlanjutan.com/index.php/jisdb" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px]" src="../assets/journal/JISDB2.png" />
            <h1 class="text-abhu lg:px-10">
              Journal of Information System, Digitazation and Business
            </h1>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>
  
<script>
import "@/assets/tailwind.css";

export default {};
</script>
  
<style></style>