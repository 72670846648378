<template>
  <div id="footer">
    <div class="pt-[100px] px-10 lg:pt-2 2xl:px-40 flex-container bg-puter bg-cover">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/5">
          <div class="lg:mt-20">
            <img src="../assets/batara1.png" alt="catra" class="rounded-[20px] max-w-full shadow-xl mb-5 lg:mx-auto" />
          </div>
          <div class="grid justify-items-center grid-cols-3 lg:grid-cols-3">
            <div class>
              <a>
                <img src="../assets/facebook.png" alt="catra"
                  class="rounded-[20px] max-w-full transform hover:scale-125 duration-300" />
              </a>
            </div>
            <div class>
              <a href="https://www.instagram.com/baswasti/" target="_blank">
                <img src="../assets/instagram.png" alt="catra"
                  class="rounded-[20px] max-w-full transform hover:scale-125 duration-300" />
              </a>
            </div>
            <div class>
              <a>
                <img src="../assets/twitter.png" alt="catra"
                  class="rounded-[20px] max-w-full transform hover:scale-125 duration-300" />
              </a>
            </div>
          </div>
        </div>
        <div class="w-full self-start lg:w-1/5">
          <div class="mt-10 lg:mt-20">
            <h1 class="text-md text-hideung font-bold mb-5">Company</h1>
            <router-link to="/" custom v-slot="{ navigate }">
              <button @click="navigate" role="link">
                <a class="text-base text-hideung transition duration-100 ease-in-out hover:underline">Home</a>
              </button>
            </router-link>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1 mt-1">Services</h2>
            </a>
            <router-link to="/blogs" custom v-slot="{ navigate }">
              <button @click="navigate" role="link">
                <a class="text-base text-hideung transition duration-100 ease-in-out hover:underline">About Us</a>
              </button>
            </router-link>
            <a href="#portofolio" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1 mt-1">Portofolio</h2>
            </a>
            <a href="#footer" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Contact</h2>
            </a>
          </div>
        </div>
        <div class="w-full self-start px-0 lg:w-1/5">
          <div class="mt-10 lg:mt-20">
            <h1 class="text-md text-hideung font-bold mb-5">What We Do</h1>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Website Development</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Mobile App Development</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Business Research and Development</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Taxation and Finance</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Sustainable Finance</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Annual and Sustainability Report</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">GCG Assesment</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Phyton For Data Science</h2>
            </a>
            <a href="#service" class="text-base text-hideung transition duration-100 ease-in-out hover:underline">
              <h2 class="mb-1">Brand Management</h2>
            </a>
          </div>
        </div>
        <div class="w-full self-start px-0 lg:w-1/3">
          <div class="mt-10 ml-0 lg:ml-12 lg:mt-20">
            <h1 class="text-md text-hideung font-bold mb-5">Contact</h1>
            <h1 class="mb-3 text-base text-hideung">
              <img src="../assets/pin.png" alt="catra"
                class="transform hover:scale-125 duration-300 inline h-[25px] mr-5" />Jln. Strawberry I.B No. Kelurahan
              Panarung,
              <span class="block ml-[38px]">Kecamatan Pahandut. Kota Palangka Raya Provinsi Kalimantan
                Tengah</span>
            </h1>
            <h1 class="mb-3 text-base text-hideung">
              <a href="mailto:info@haraticonsulting.co.id">
                <img src="../assets/mail.png" alt="catra"
                  class="transform hover:scale-125 duration-300 inline h-[25px] mr-4" /> </a>
              info@haraticonsulting.co.id
            </h1>
            <h1 class="mb-1 text-base text-hideung">
              <a href="https://wa.me/6281120023046">
                <img src="../assets/phone.png" alt="catra"
                  class="transform hover:scale-125 duration-300 inline h-[25px] mr-4" /> </a>Mobile: 0811-2002-3046
            </h1>
          </div>
        </div>
        <hr class="w-[1221px] h-[1px] mx-auto mt-10 mb-5 border-0 bg-hideung" />
      </div>
      <a href="http://haraticonsulting.co.id/" class="text-base text-center text-hideung transition duration-100 ease-in-out hover:underline">
        <h2 class="mb-1">Copyright © 2024 PT. Batara Swastika Harati</h2>
      </a>
      <br />
    </div>

    <!-- <div class="bg-cover bg-center bg-no-repeat h-[700px] bg-cats pt-0">
        <div class="grid grid-cols-2 gap-10 pt-15 h-[700px]">
          <div class="text-left p-60">
            <p class="text-4xl font-extrabold">
              SCIENTIFICS
              <a class="text-primary">RESEARCH</a>
            </p>
            <p class="text-4xl font-extrabold">
              JOURNAL
            </p>
            <a href="https://api.whatsapp.com/send/?phone=6281120011644&text&type=phone_number&app_absent=0">
            <button
              type="button"
              class="text-bodas bg-primary hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-primary dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >Consultation NOW !</button>
          </a>
          </div>
          <div class="place-content-center">
            <div class>
              <img class="h-[629px] w-[629px]" src="../assets/home1.png" alt="homie" />
            </div>
          </div>
        </div>
    </div>-->
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>