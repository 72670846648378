<template>
  <div id="service">
    <div class="pt-[70px] px-10 2xl:px-60 flex-container">
      <div data-aos="fade-down" data-aos-offset="300" data-aos-duration="500"
        class="text-center">
        <h1 class="text-lg font-bold pt-10">How We Can Help Your Business</h1>
        <h2 class="mb-10 text-base text-abhu">We build readymade websites, Mobile Applications, Research and
          Development, and Assessment.</h2>
      </div>
      <div data-aos="fade-up" data-aos-duration="1500"
        class="justify-items-center grid grid-cols-2 gap-4 lg:grid-cols-3">
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/laptop.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Website Development
          </h1>
          <h2 class="text-center text-base text-abhu mt-2">Website Development, Solution for your business</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/apps.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Mobile App Development </h1>
          <h2 class="text-center text-base text-abhu mt-2">Mobile application development solutions for your business
          </h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/business.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Business Research and Development
          </h1>
          <h2 class="text-center text-base text-abhu mt-2">Help solve problems, increase knowledge, make better
            interpretations and discover new facts.</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/tax.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Taxation and Finance
          </h1>
          <h2 class="text-center text-base text-abhu mt-2">In providing guarantees for the Sustainability Report, an
            independent party is needed to provide it</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/finance.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Sustainable Finance </h1>
          <h2 class="text-center text-base text-abhu mt-2">Offering training related to the implementation of
            sustainable finance assisting in the preparation of the Sustainable Finance Action Plan (RAKB)</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/report.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Annual and Sustainability Report
          </h1>
          <h2 class="text-center text-base text-abhu mt-2">The annual report is prepared using the Annual Report Award
            (ARA) criteria</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/cgc.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            GCG Assesment
          </h1>
          <h2 class="text-center text-base text-abhu mt-2">Good Corporate Governance Assessment for companies within
            BUMN uses instruments that have been developed by the Ministry of BUMN</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/python.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Phyton For Data Science </h1>
          <h2 class="text-center text-base text-abhu mt-2">Solving a problem by identifying and evaluating problems,
            opportunities, obstacles that occur</h2>
        </div>
        <div class="p-6 rounded-xl transition duration-300 ease-in-out hover:border-2 border-primary">
          <img class="mx-auto h-[150px]" src="../assets/manage.svg" />
          <h1 class="text-center text-md text-hideung font-bold mt-5">
            Brand Management
          </h1>
          <h2 class="text-center text-base text-abhu mt-2">Assisting clients in compiling a Brand Management Strategy to
            increase the value generated</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>