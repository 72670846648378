<template>
  <div id="about">
    <div class="pt-[50px] lg:pt-[150px] 2xl:px-60 flex-container">
      <div class="flex flex-wrap">
        <div data-aos="fade-up" data-aos-offset="300" data-aos-duration="500" class="mt-20 w-full pl-5 lg:w-1/2">
          <h1 class="mb-10 text-lg font-bold">
            About
            <span class="text-primary">Us</span>
          </h1>
          <h2 class="pb-10 text-abhu">PT. Batara Swastika Harati is a consulting company domiciled in Palangka Raya City and
            was established on Decembaer 13, 2023 based on Notary deed Number: -08- before Notary Indra Prayitno,
            S.H.,M.Kn. The purpose of establishing this consulting firm is to provide consulting services and research
            studies.</h2>
          <router-link to="/blogs" custom v-slot="{ navigate }">
            <button @click="navigate" role="link">
              <a
                class="transition duration-300 ease-in-out hover:bg-hideung text-bodas font-bold bg-primary py-4 px-5 rounded-full">
                About
                Us
              </a>
            </button>
          </router-link>
        </div>
        <div data-aos="fade-up" data-aos-offset="500" data-aos-duration="500" class="w-full self-end px-4 lg:w-1/2">
          <div class="mt-10">
            <img src="../assets/ourExpertise.png" alt="catra" class="max-w-full mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import "@/assets/tailwind.css";

export default {};
</script>
    
<style></style>